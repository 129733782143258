
import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Card, CardContent, Button, Grid } from '@mui/material';
import Layout from '../components/Layout';
import { getAllCourses } from '../service/service'; // Import the service function
import { useNavigate } from 'react-router-dom';

const ReviewVoiceNotes = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true); // Start loading
      try {
        const data = await getAllCourses(); // Call the service function
        setCourses(data.data); // Set courses in state
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchCourses(); // Fetch courses when the component mounts
  }, []);

  const handleEdit = (id, name) => {
    navigate(`/voice-notes/${id}/${name}`); // Navigate to edit page with course ID
  };

  return (
    <Layout>
      {loading ? ( // Show loading indicator
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          {courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} key={course.id}> {/* Adjust the key according to your data structure */}
              <Card>
                <CardContent>
                  <Typography variant="h5">{course.name}</Typography> {/* Adjust according to your course structure */}
                  <Typography variant="body2">{course.description}</Typography> {/* Adjust according to your course structure */}
                  <Button variant="contained" color="primary"   onClick={() => handleEdit(course._id, course.name)} style={{ marginTop: '16px' }}>
                    View Voice Notes 
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Layout>
  );
};

export default ReviewVoiceNotes 
