// Base URL of the backend API
export const BASE_URL = process.env.REACT_APP_BACKEND_URL ; // Update with your actual backend URL

// Endpoints Object
export const ENDPOINTS = {
  loginAdmin: '/api/v1/admin/login',
  getAllCourse: '/api/v1/course/getAllCourse',
  getCourseById: '/api/v1/course/getSingleCourse',
  updateCourseById: '/api/v1/course/updateCourse',
  getUnitDetails: '/api/v1/unit/details',
  updateLevel: '/api/v1/level/updateLevelForAdmin',
  getAllUviForAdmin: `/api/v1/question/getAllUviForAdmin`,
  reviewQuestion: `/api/v1/question/reviewQuestion`,

  // You can add more endpoints here as needed (e.g., registration, etc.)
};
