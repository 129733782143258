import axios from 'axios';
import { BASE_URL, ENDPOINTS } from './config';

// Wrapper function for admin login
export const loginAdmin = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}${ENDPOINTS.loginAdmin}`, {
      email,
      password,
    });

    return response.data; // Return response data (token, etc.)
  } catch (error) {
    console.error('Error during admin login:', error.response?.data || error.message);
    throw error.response?.data || error;
  }
};



const makePrivateRequest = {
  async get(url, config) {
    return await this.request("get", url, config);
  },

  async post(url, data, config) {
    return await this.request("post", url, data, config);
  },

  async put(url, data, config) {
    return await this.request("put", url, data, config);
  },

  async patch(url, data, config) {
    return await this.request("patch", url, data, config);
  },

  async delete(url, config) {
    return await this.request("delete", url, config);
  },

  async request(method, url, data = null, config = {}) {
    const token = localStorage.getItem("token");
    try {

      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response = await axios({
        method,
        url,
        data,
        ...config,
      });

      return response.data;
    } catch (error) {
      // Handle errors, e.g., redirect to login page for unauthorized access
      const status = error?.response?.status;

      console.error("Error in making request", status, error?.response?.data?.err, token);
      if (status === 400 && error.response.data.err === 'Invalid token') {
        localStorage.removeItem("token");
        window.location.href = '/login';
      }

      console.error("Error:", error);
      throw error;
    }
  },
};
export const getAllCourses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${ENDPOINTS.getAllCourse}`);
      return response.data; // Assuming your API returns data in the body
    } catch (error) {
      console.error("Error fetching courses:", error);
      throw error; // Rethrow error for handling in the calling function
    }
  };


  export const getCourseById = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}${ENDPOINTS.getCourseById}/${id}`);
      return response.data; // Assuming your API returns data in the body
    } catch (error) {
      console.error("Error fetching course by Id:", error);
      throw error; // Rethrow error for handling in the calling function
    }
  };


  export const updateCourseById = async (id, data) => {
    try {
      const response = await axios.patch(`${BASE_URL}${ENDPOINTS.updateCourseById}/${id}`, data);
      return response.data; // Assuming your API returns data in the body
    } catch (error) {
      console.error("Error updating course by Id:", error);
      throw error; // Rethrow error for handling in the calling function
    }
  };


  export const getUnitDetails = async (courseId, unitId) => {
    try {
      const response = await axios.get(`${BASE_URL}${ENDPOINTS.getUnitDetails}/${courseId}/${unitId}`);
      return response.data; // Assuming your API returns data in the body
    } catch (error) {
      console.error("Error updating course by Id:", error);
      throw error; // Rethrow error for handling in the calling function
    }
  };


  export const updateLevelById = async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}${ENDPOINTS.updateLevel}/${id}`, {levelData: data});
      return response.data; 
    } catch (error) {
      console.error("Error updating course by Id:", error);
      return error; 
    }
  };

  export const getAllUviForAdmin  = async (courseId , courseName) => {
    try {
      const response = await makePrivateRequest.get(`${BASE_URL}${ENDPOINTS.getAllUviForAdmin}?courseId=${courseId}&courseName=${courseName}`);
      return response.data; 
    } catch (error) {
      console.error("Error :", error);
      return error; 
    }
  };

  export const reviewQuestion  = async (enrolledQuestionId,  expertAction, expertRemark) => {
    try {
      const response = await makePrivateRequest.post(`${BASE_URL}${ENDPOINTS.reviewQuestion}`, {enrolledQuestionId, expertRemark, expertAction});
      return response; 
    } catch (error) {
      console.error("Error :", error);
      return error; 
    }
  };


  