import { createTheme } from '@mui/material/styles';

// Define your theme settings here
const theme = createTheme({
  palette: {
    primary: {
      main: '#61FE6F',
      lighterShade: '#B6FFBD' // Set your primary color here
    },
    secondary: {
      main: '#dc004e', // Set your secondary color here
    },
    background: {
      default: '#f5f5f5', // Default background color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Default font family
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& :-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            WebkitTextFillColor: 'black',
          },
        },
      },
    },
  },
});

export default theme;
