import React, { useState } from 'react';
import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import VoiceRow from './VoiceRow';

const UnitGroup = ({ unitName, voices, courseName  , setVoices, handleDelete }) => {
  const [currentPlayingId, setCurrentPlayingId] = useState(null); // Track which audio is playing

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Unit name: {unitName} - {courseName}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Level</TableCell>
              <TableCell >Email</TableCell> 
              <TableCell >Date</TableCell> 
              <TableCell sx={{ width: '30%' }}>Question</TableCell> 
              <TableCell>Play/Pause</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Submit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {voices
              .sort((a, b) => a.order - b.order) // Sort by order
              .map((voice) => (
                <VoiceRow
                  key={voice._id}
                  voice={voice}
                  currentPlayingId={currentPlayingId}
                  setCurrentPlayingId={setCurrentPlayingId}
                  setVoices={setVoices}
                  handleDelete={handleDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UnitGroup;
