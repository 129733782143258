import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, MenuItem, Select, FormControl, InputLabel, Grid, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Divider, Grid2 } from '@mui/material';
import { getUnitDetails, updateLevelById } from '../service/service'; // Assuming you have this service call
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const questionTypes = ["MC", "MCM", "ACIQ", "ACMC", "CT", "ACIQM", "UVI", "LAR", "CTA"]; // Example options for question type

const hintTypes = ["audio", "text"];

// Create a custom alert component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UnitEditPage = () => {
  const { courseId, unitId } = useParams();
  const navigate = useNavigate();
  const [unitData, setUnitData] = useState(null);
  const [levels, setLevels] = useState([]);
  const [originalLevels, setOriginalLevels] = useState([]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  useEffect(() => {
    const fetchUnitDetails = async () => {
      try {
        const { data } = await getUnitDetails(courseId, unitId);
        setUnitData(data.unit);
        setLevels(data.levels);
        setOriginalLevels(data.levels)
      } catch (error) {
        console.error('Failed to fetch unit details:', error);
      }
    };
    fetchUnitDetails();
  }, [unitId]);


  const handleLevelChange = (index, key, value) => {
    const updatedLevels = levels.map((level, i) => (i === index ? { ...level, [key]: value } : level));
    setLevels(updatedLevels);
  };

  const handleQuestionChange = (levelIndex, questionIndex, key, value) => {
    const updatedLevels = [...levels];
    updatedLevels[levelIndex].questions[questionIndex][key] = value;
    updatedLevels[levelIndex].questions[questionIndex].isModified = true;
    // const updatedLevels = levels.map((level, i) => 
    //   i === levelIndex
    //     ? { ...level, questions: level.questions.map((q, j) => (j === questionIndex ? { ...q, [key]: value } : q)) }
    //     : level
    // );

    setLevels(updatedLevels);
  };

  const handleOptionChange = (levelIndex, questionIndex, optionIndex, key, value) => {
    console.log(">>>> handle Options change", levelIndex, questionIndex, optionIndex, key, value)
    const updatedLevels = [...levels];

    const { questionType, Answer } = updatedLevels[levelIndex].questions[questionIndex];
    const oldOptionValue = updatedLevels[levelIndex].questions[questionIndex].options[optionIndex]
    if (questionType === 'MCM') {
      const answerArray = Answer.split('; ');
      if (answerArray.includes(oldOptionValue)) {
        const newAnswer = answerArray.filter(option => option !== oldOptionValue);
        newAnswer.push(value);
        updatedLevels[levelIndex].questions[questionIndex].Answer = newAnswer.join('; ');
      }
    } else {
      if (oldOptionValue === Answer) {
        updatedLevels[levelIndex].questions[questionIndex].Answer = value;
      }
    }

    updatedLevels[levelIndex].questions[questionIndex].options[optionIndex] = value;
    updatedLevels[levelIndex].questions[questionIndex].isModified = true;
    setLevels(updatedLevels);
  };

  const hanldeAnswerChange = (levelIndex, questionIndex, optionIndex, questionType, Answer) => {
    console.log(">>> Handle Answer Change ", levelIndex, questionIndex, optionIndex, questionType, Answer);
    const updatedLevels = [...levels];

    switch(questionType) {
      case 'MC': {
        updatedLevels[levelIndex].questions[questionIndex].Answer = Answer;
        break;
      }
      case 'ACIQ': {
        updatedLevels[levelIndex].questions[questionIndex].Answer = Answer;
        break;
      }
      default:
        console.log("Unknown questionType", questionType);
    }
    updatedLevels[levelIndex].questions[questionIndex].isModified = true;
    setLevels(updatedLevels);
  }


  const handleUpdateLevel = async (levelId, levelData, levelIndex) => {
    setLoading(true);
    try {
      const modifiedQuestions = levelData.questions.filter((questionData) => questionData.isModified);
      // levelData.questions = modifiedQuestions;
      const res = await updateLevelById(levelId, levelData);
      console.log("Updated Res --- ---", res);
      if (res.isValid) {
        setMessage(res.message); // Set the success message
        setOpen(true); // Open the toast
      } else {
        setMessage('Error occurred while updating levels and question');
        setSeverity('error'); // Red toast for error
      }
    } catch (error) {
      console.error('Failed to update level:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false); // Close the toast when done
  };

  const isOptionCorrect = (option, answer, type) => {
    let result = false;

    switch(type) {
      case 'MC': {
        result = option?.toLowerCase() === answer.toLowerCase();
        break;
      }

      case 'ACIQ': {
        result = option?.toLowerCase() === answer.toLowerCase();
        break;
      }

      case 'MCM': {
        result = answer?.split('; ').includes(option);
        break;
      }
      default: 
        console.log("Unknown type", type);
    }

    return result
  };

  if (!unitData) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%', width: '100%', backgroundColor: 'background.default', p: 4 }}>
      <Paper elevation={0} sx={{ padding: '2rem', width: '100%' }}>
        {/* Unit Information (Read-only) */}
        <Typography variant="h5" gutterBottom>Edit Unit: {unitData.name}</Typography>
        <Typography variant="subtitle1">Order: {unitData.order}</Typography>
        <Typography variant="subtitle1">Bonus: {unitData.isBonus ? 'Yes' : 'No'}</Typography>

        {/* Level Sections */}
        {levels.map((level, levelIndex) => (
          <Paper
            key={level._id}
            sx={{ 
              mt: 3,
              mb: 3,
              padding: '24px',
              border: '1px solid silver',
            }}
          >
            <Grid container sx={{ padding: "24px"}}>
              {/* Editable Level Name */}
              <Grid item xs={12} md={12}>
                  <Typography sx={{mt:2, mb: 2}} variant="h6">Level {level.order}</Typography>

              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Level Name"
                  variant="outlined"
                  fullWidth
                  value={level.name}
                  onChange={(e) => handleLevelChange(levelIndex, 'name', e.target.value)}
                />
              </Grid>

              {/* Questions */}
              {level.questions.map((question, questionIndex) => (
                <>
                  <br />
                  <Typography sx={{mt:2, mb: 2}} variant="h6">Question: {question.order}</Typography>
                  <br />
                  <Grid container spacing={2} key={question._id}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Question Type</InputLabel>
                        <Select
                          value={question.questionType}
                          label="Question Type"
                          onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'questionType', e.target.value)}
                        >
                          {questionTypes.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <TextField
                        label="Question"
                        variant="outlined"
                        fullWidth
                        value={question.question}
                        onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'question', e.target.value)}
                      />
                    </Grid>

                    {/* {console.log("question options ----", question.Answer)} */}

                    {/* Render Options in Table Form */}
                  {!['ACMC', 'UVI', 'CT', 'LAR'].includes(question.questionType) && <Grid item xs={12}>
                      <Table sx={{ background: 'primary.lighterShade'}}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Option</TableCell>
                            <TableCell>Is Correct Answer</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {question?.options?.map((option, optionIndex) => (
                            <TableRow key={option._id}>
                              <TableCell>
                                <TextField
                                  value={option}
                                  fullWidth
                                  onChange={(e) => handleOptionChange(levelIndex, questionIndex, optionIndex, 'value', e.target.value)}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={isOptionCorrect(option, question.Answer, question.questionType)}
                                  onChange={(e) => hanldeAnswerChange(levelIndex, questionIndex, optionIndex,  question.questionType, option)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
}
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Note Brief"
                        variant="outlined"
                        fullWidth
                        value={question.noteBrief}
                        onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'noteBrief', e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Note Full"
                        variant="outlined"
                        fullWidth
                        value={question.noteFull}
                        onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'noteFull', e.target.value)}
                      />
                    </Grid>


                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Hint Type</InputLabel>
                        <Select
                          value={question.hintType}
                          label="Hint Type"
                          onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'hintType', e.target.value)}
                        >
                          {hintTypes.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>


                    {question.questionType === "UVI" | question.questionType === "LAR" && 
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Phrase"
                        variant="outlined"
                        fullWidth
                        value={question.phrase}
                        onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'phrase', e.target.value)}
                      />
                    </Grid>
                    }


                   {question.hintType === "text" && <Grid item xs={12} md={8}>
                      <TextField
                        label="Hint"
                        variant="outlined"
                        fullWidth
                        value={question.hint}
                        onChange={(e) => handleQuestionChange(levelIndex, questionIndex, 'hint', e.target.value)}
                      />
                    </Grid>}


                    <Grid item xs={12} md={12}>
                      <Divider 
                        sx={{
                          borderBottomWidth: 3,  // Set the thickness
                          borderColor: 'primary.main',
                          mt: 4,
                          mb: 4   // Set the color to blue
                        }}
                      />                  
                    </Grid>
                  
                  
                  </Grid>
                  
                  <br />
                </>
              ))}
            </Grid>

            {/* Save Button for Each Level */}
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => handleUpdateLevel(level._id, levels[levelIndex], levelIndex)}
              disabled={loading}
            >
              {loading ? 'Saving...' : `Save Level ${level.order}`}
            </Button>
          </Paper>
        ))}
      </Paper>

      <Snackbar
        open={open}
        autoHideDuration={4000} // Toast will auto close after 4 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position of the toast
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UnitEditPage;
