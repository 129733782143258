import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext, useAuthContext } from '../context/AuthContext';

const PrivateRoute = ({ element }) => {
    const { authState } = useAuthContext();
    const token = localStorage.getItem('token'); 
  
    return token  ? element : <Navigate to="/" replace />;
};
export default PrivateRoute;
